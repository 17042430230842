#navbar-main{
  position: sticky;
  top: 0;
  /* height: 81px; */
  z-index: 3;
}
.pointsModal .modal-dialog {
  max-width: 1000px !important;
}
.pointsModal .modal-content {
  border: 1px solid rgba(251, 176, 64, 0.4);
  background-color: #000000E5 !important ;
  overflow: hidden;
}
.pointsModal .heading {
  color: #fff;
}
.pointsModal .heading span {
  color: #FBB040;
}
.pointsModal .sliderMain {
  border: 1px solid rgba(251, 176, 64, 0.3);
  padding: 20px;
  margin: 20px 0;
  border-radius: 20px;
}
.pointsModal .sliderMain .r1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.pointsModal .sliderMain .r1 .col1 {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}
.pointsModal .sliderMain .r1 .col1 .fil {
  border: 0.5px solid #676767;
  cursor: pointer;
  padding: 3px 16px;
  border-radius: 20px;
  margin-right: 6px;
  color: #474747;
  font-size: 9px;
}
.pointsModal .sliderMain .r1 .col1 .fil.active, .pointsModal .sliderMain .r1 .col1 .fil:hover {
  border: 0.5px solid #FBB040;
  color: #FBB040;
}
.pointsModal .sliderMain .r1 .col2 .pointsVal {
  color: #B9B9B9;
  font-size: 12px;
}
.pointsModal .sliderMain .r1 .col2 .pointsVal span {
  color: #FBB040;
}
.pointsModal .sliderMain .r2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pointsModal .sliderMain .r2 .balance {
  color: #B9B9B9;
  font-size: 9px;
}
.pointsModal .sliderMain .r2 .balance span {
  color: #FBB040;
  font-size: 12px;
  font-weight: 500;
}
.pointsModal .themeBtn {
  font-size: 10px;
  border-radius: 8px;
  padding: 5px 10px;
}
.themeBtn {
  background: #FBB040;
  border-radius: 12px;
  color: #000;
  font-weight: bold;
  padding: 7px 20px;
  border: 1px solid #FBB040;
  transition: all 0.2s;
}
.themeBtn:hover {
  box-shadow: rgb(247, 178, 74, 0.8) 0px 0px 8px 4px;
  transition: all 0.2s;
}
.pointsModal .sliderMain .inputRow {
  display: flex;
  justify-content: space-between;
}
.pointsModal .sliderMain .inputRow .col1 {
  width: 100%;
  /* margin-right: 20px; */
}
.pointsModal .sliderMain .inputRow .col1 .inputM {
  display: flex;
  border: 0.5px solid #77797E;
  border-radius: 6px;
  padding: 5px 20px;
  background: #131313;
}
.pointsModal .sliderMain .inputRow .col1 .inputM input:focus {
  outline: none;
}
.pointsModal .sliderMain .inputRow .col1 .inputM input {
  width: 100%;
  background: transparent;
  border: none;
  color: #FBB040;
  font-size: 9px;
}
.pointsModal .sliderMain .inputRow .col1 .inputM .balance {
  display: flex;
  color: #B9B9B9;
  font-size: 10px;
}
.pointsModal .sliderMain .inputRow .col2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.pointsModal .sliderMain .inputRow .col2 .fil.active {
  border: 0.5px solid #FBB040;
}
.pointsModal .sliderMain .inputRow .col2 .fil {
  border: 0.5px solid #676767;
  padding: 3px 16px;
  border-radius: 20px;
  margin-right: 6px;
  color: #474747;
  cursor: pointer;
  font-size: 9px;
}
.pointsModal .sliderMain .inputRow .col1 .inputM .balance span {
  color: #FBB040;
  display: inline-block;
  margin: 0 7px;
  font-weight: 400;
}
.pointsModal .walletCol {
  background-color: #181818;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 450px;
  }
.pointsModal .walletCol .heading {
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.pointsModal .walletCol .heading span {
  font-size: 12px;
  padding: 4px 10px;
  background: #000;
  border: 1px solid rgba(251, 176, 64, 0.3);
  border-radius: 8px;
}
.pointsModal .walletCol .btnM {
  background: #000;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  margin: 20px 0;
  }
.pointsModal .walletCol .btnM button.active {
  background: #FBB040;
  color: #000;
}
.pointsModal .walletCol .btnM button {
  background: transparent;
  color: white;
  width: 100%;
  border: none;
  padding: 10px;
  font-size: 20px;
  }
  .pointsModal .walletCol .option.active {
    background-color: #000;
  }
  .pointsModal .walletCol .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 12px;
    border-radius: 12px;
    margin: 5px 0;
    cursor: pointer;
  }
  .pointsModal .walletCol .option .name {
    display: flex;
    align-items: center;
  }
  .pointsModal .walletCol .option .name img {
    width: 24px;
    margin-right: 10px;
  }
  .pointsModal .walletCol .option span {
    font-size: 13px;
    padding: 4px 10px;
    background: #000;
    border: 1px solid rgba(251, 176, 64, 0.3);
    border-radius: 8px;
    color: #FBB040;
    }
  .pointsModal .walletCol .historyBtn {
    background: #000;
    border-radius: 12px;
    padding: 16px;
    text-align: center;
    margin-top: auto;
  }
  
.rangeslider-horizontal .rangeslider__fill {
  background-color: #FBB040 !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
  background-color: #FBB040 !important;
}
.rangeslider .rangeslider__handle {
  background-color: #000 !important;
  border: 1px solid #000 !important;
}
.rangeslider {
  background: #222222 !important;
  border: 0.5px solid #77797E !important;
}
.pointsModal .sliderMain .inputRow .sbtn {
  margin-top: 40px;
}
.pointsModal .closeIco {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 18px;
  cursor: pointer;
}
.nav-desposit-row {

}
.navbar .socialRow {
  display: flex;
  align-items: center;
}
.navbar .socialRow a {
  display: inline-block;
  margin:  0 10px ;
}
.navbar .socialRow a:hover svg, .navbar .socialRow a:hover svg path {
  fill: #fff;
  opacity: 1;
}

.chatMain {
  position: fixed;
  right: -370px;
  top: 81px;
  height: calc(100vh - 81px);
  padding: 20px 15px;
  background-color: #171717;
  border-radius: 16px;
  max-width: 370px;
  width: 100%;
  transition: all 0.3s;
}
.chatMain .heading {
  color: rgba(255, 255, 255, 0.5);
}
.chatMain .heading img {
  width: 20px;
}
.chatMain .searchMain {
  margin: 10px 0;
  background-color: #252525;
  padding: 4px 10px;
  border-radius: 20px;
}
.chatMain .searchMain img {
  width: 20px;
}
.chatMain .searchMain input {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.3);
  outline: none;
  width: 100%;
}
.chatMain .chatCardMain {
  overflow-y: auto;
  height: calc(100% - 90px);
}

.chatMain .chatCardMain::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #000;
}

.chatMain .chatCardMain::-webkit-scrollbar
{
	width: 6px;
	background-color: #000;
}

.chatMain .chatCardMain::-webkit-scrollbar-thumb
{
	background-color: rgb(56, 56, 56);
    border: 1px solid rgb(56, 56, 56);
}

.chatMain .downGradient {
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
  width: 100%;
  height: 80px;
}
.chatMain .chatCard {
  display: flex;
  align-items: flex-start;
  padding: 6px 0;
}
.chatMain .chatCard .profile {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.chatMain .chatCard .profile img {
  width: 100%;
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.chatMain .chatCard .msgM {
  padding: 10px;
}
.chatMain .chatCard .msgM .name {
  color: #fff;
  font-size: 18px;
}
.chatMain .chatCard .msgM .msg {
  font-size: 11px;
  color: #A7A7A7;
}
.chatMain .chatCard .time {
  font-size: 12px;
  padding: 10px 0;
}

@media screen and (max-width:1200px) {
  .nav-desposit-row {
    flex-direction: column;
  }
  .navbar .nav-msg {
    flex-direction: column;
    margin: 0 1rem;
  }
  .navbar .socialRow {
    margin: 10px 0;
  }
  .navbar .theme-btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width:768px) {
  .pointsModal .sliderMain .inputRow {
    flex-direction: column;
  }
  .pointsModal .sliderMain .inputRow .sbtn {
    text-align: center;
    margin-top: 20px;
  }
}