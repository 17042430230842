@font-face {
  font-family: GothamRegular;
  src: url("fonts/Gotham/Gotham-Light.otf") format("truetype");
}
@font-face {
  font-family: GothamMedium;
  src: url("fonts/Gotham/Gotham-Medium.otf") format("truetype");
}
@font-face {
  font-family: GothamBold;
  src: url("fonts/Gotham/Gotham-Bold.otf") format("truetype");
}
@font-face {
  font-family: GothamLight;
  src: url("fonts/Gotham/Gotham-Light.otf") format("truetype");
}
@font-face {
  font-family: GothamBoldItalic;
  src: url("fonts/Gotham/Gotham-BoldItalic.otf") format("truetype");
}
@font-face {
  font-family: GothamItalic;
  src: url("fonts/Gotham/Gotham-MediumItalic.otf") format("truetype");
}
@font-face {
  font-family: GothamLightItalic;
  src: url("fonts/Gotham/Gotham-LightItalic.otf") format("truetype");
}

:root{
  --themeYellow:#f8ad3e;
}

body {
  margin: 0;
  font-family: GothamMedium, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #101010 !important;
  color: #a5a5a5 !important;
}
.font-gotham-bold{
  font-family: GothamBold;
}
.font-gotham-light{
  font-family: GothamLight;
}

.font-gotham-italic{
  font-family: GothamItalic;
}

.Toastify__progress-bar-theme--dark {
  background: var(--themeYellow) !important;
}
.table-responsive::-webkit-scrollbar {
  width: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #2c2c2c;
}

/* Aggiungi uno stile personalizzato alla barra di scorrimento */
.table-responsive {
  scrollbar-color: #888 #2c2c2c;
  scrollbar-width: thin;
}


/* admin */
/* admin */

.loginPage {
  min-height: 100vh;
  padding: 60px 0;
  color: white;
  display: flex;
  align-items: center;
}
.loginCard{
width: 340px;
background: #0c0f0a;
margin: auto;
padding: 30px;
border-top: 4px solid var(--themeYellow);
  box-shadow: 0 0 20px 0 rgba(248,173,62,0.5), 0 5px 5px 0 rgba(248,173,62,0.2);
}

.loginCard h5{
margin: 0;
color: #fff;
font-size: 20px;
text-align: center;
height: 40px;
border-bottom: 1px solid;
margin-bottom: 30px;
font-family: sans-serif;
}

.loginCard .text{
width: 94%;
color: #fff;
border: 1px solid var(--themeYellow);
background: #212121;
font-size: 14px;
padding: 14px 10px;
font-weight: bold;
margin-bottom: 16px;
}

.loginCard .btn{
border: none;
width: 100%;
height: 42px;
background: var(--themeYellow);
color: white;
font-weight: bold;
}

.createBetPage {
  color: white;
  padding: 60px 0;
}
.createBetPage table tr th,.createBetPage table tr td{
  color: white;
  background-color: #0f0f0f ;
  border-bottom: none;
}
.createBetPage .cardImg {
  width: 100px;
  height: 100px;
}
.createBetPage .cardImg img{
  max-width: 100%;
  max-height: 100%;
}
.createBetPage .filterRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: max-content;
  padding: 8px;
  border-radius: 12px;
  background-color: #000;
}
.createBetPage .filterRow button {
  background-color: transparent;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all 0.3s;
  cursor: pointer;
}
.createBetPage .filterRow button.active {
  background-color: rgb(247, 178, 74);
  border: 1px solid rgb(247, 178, 74);
}
.jc-center {
  justify-content: center;
}
.betModal {
  position: relative;
  /* box-shadow: 0 0 20px 0 rgba(0,145,0,0.5), 0 5px 5px 0 rgba(0,145,0,0.2); */
  padding: 30px;
  border-radius: 30px ;
}
.betModal .closeIcon{
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
}
.betModal .closeIcon i{
  font-size: 25px;
  color: #707070;
}
.betModal .text{
  width: 100%;
color: #fff;
border: 1px solid var(--themeYellow);
background: #212121;
font-size: 14px;
padding: 14px 10px;
font-weight: bold;
margin-bottom: 16px;
}
.betModal h2 {
  margin: 0;
color: #fff;
font-size: 20px;
text-align: center;
height: 40px;
border-bottom: 1px solid;
margin-bottom: 30px;
font-family: sans-serif;
}
.betModal .btn {
  border: none;
width: 100%;
height: 42px;
background: var(--themeYellow);
color: white;
font-weight: bold;
}
.adminNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adminNav .logo{
  max-width: 140px;
}
/* admin */
