.faqPage {
    padding: 30px 3rem;
    min-height: calc(100vh - 354px);
}
.faqPage h1 {
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
}
.faqPage .accordion-item {
    background-color: #181818;
    color: rgb(165, 165, 165);
    border-left: none;
    border-right: none;
    border-color: #FBB040;
    border-radius: unset !important;
}
.accordion-item:first-of-type>.accordion-header .accordion-button {
    border-radius: unset !important;
}
.faqPage .accordion-button:active,.faqPage .accordion-button:focus {
    box-shadow: none;
}
.faqPage .accordion-button {
    background-color: #181818;
    color: #fff;
}
.faqPage .accordion-button:not(.collapsed) {
    background-color: #FBB040;
    color: #000;
}
.faqPage .accordion-button:not(.collapsed)::after {
    filter:  invert(1) brightness(0) ;
}
.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FBB040'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
/* .faqPage .accordion-button::after {
    filter: brightness(0) invert(1);
} */