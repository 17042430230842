.footballPage .bannerSec {
    padding: 100px 15px;
    text-align: center;
    background: url(../../imgs/footballBanner.png);
    background-position: center;
    background-size: cover;
    border-radius: 20px;
}
.footballPage .bannerSec h1 {
    font-size: 40px;
    color: #fff;
    min-height: 42px;
}
.footballPage .bannerSec .tagline {
    color: #9E9E9E;
    font-size: 16px;
    max-width: 260px;
    margin: auto;
    margin-bottom: 15px;
}
.footballPage .sec2 {
    margin-top: 40px;
}
.footballPage .sec2 h1 {
    font-size: 28px;
    color: #fff;
    max-width: 590px;
    margin: auto;
    margin-bottom: 10px;
    text-align: center;
}
.footballPage .sec2 .tagline {
    color: #9E9E9E;
    font-size: 16px;
    max-width: 520px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.footballPage .footballSec {
    padding: 40px 0;
}
.footballPage .footballSec .head {
    font-size: 28px;
    color: #fff;
    max-width: 590px;
    margin: auto;
    margin-bottom: 10px;
    text-align: center;
}
.footballPage .footballSec .footballCardMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 306px;
    margin: auto;
}

.footballPage .footballSec .footballCardMain .vs {
    font-size: 18px;
    text-transform: uppercase;
    color: var(--themeYellow);
}
.footballPage .footballSec .footballCardMain .fooCard {
    width: calc(50% - 14px);
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
}
.footballPage .footballSec .footballCardMain .fooCard .imgDiv {
    height: 130px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.footballPage .footballSec .footballCardMain .fooCard .imgDiv img{
    max-height: 100%;
    max-width: 100%;
}
.footballPage .footballSec .footballCardMain .fooCard .title {
    margin: 20px 0;
    font-size: 12px;
    color: var(--themeYellow);
}
.footballPage .footballSec .footballCard{
    display: flex;
    flex-direction: column;
}
.footballPage .footballSec .footballCard .yellowBtn {
    background: linear-gradient(#FBC26D,#F09104);
    border: none;
    padding: 13px 10px;
    width: 100%;
    border-radius: 12px;
    color: #fff;
    max-width: 120px;
    margin: auto;
    font-size: 15px;
}
.footballPage .footballSec .footballCard .yellowBtn:hover {
    box-shadow: rgb(247, 178, 74, 0.8) 0px 0px 8px 4px;
}
.footballPage .footballSec .footballCardMain .fooCard .grayBtn {
    background: linear-gradient(#8F8F8F,#2E2E2E);
    border: none;
    padding: 13px 10px;
    width: 100%;
    border-radius: 12px;
    color: #fff;
    max-width: 120px;
    margin: auto;
    font-size: 15px;
}
.footballPage .footballSec .footballCardMain .fooCard .grayBtn:hover {
    box-shadow: rgba(179, 179, 179, 0.8) 0px 0px 8px 4px;
}
.footballPage .footballSec .showMore {
    font-size: 15px;
    text-decoration: underline;
    margin-top: 40px;
    text-align: center;
    color: #fff;
}

/* football detail */

.footballPage .poolCardMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 306px;
    margin: auto;
}

.footballPage .poolCardMain .vs {
    font-size: 18px;
    text-transform: uppercase;
    color: var(--themeYellow);
}
.footballPage .poolCardMain .fooCard {
    width: calc(50% - 14px);
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
}
.footballPage .poolCardMain .fooCard .imgDiv {
    height: 130px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.footballPage .poolCardMain .fooCard .imgDiv img{
    max-height: 100%;
    max-width: 100%;
}
.footballPage .poolCardMain .fooCard .title {
    margin: 20px 0;
    font-size: 12px;
    color: var(--themeYellow);
}

.footballPage .poolCard {
    background-color: rgb(36, 36, 36);
    border-radius: 20px;
    margin-bottom: 20px;
}

.footballPage .poolCard .row1 {
    padding: 20px 12px;
    border-bottom: 2px solid rgb(56, 56, 56);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footballPage .poolCard .row1 .creatBtn, .footballPage .poolCard .row2 .scoreM .creatBtn {
    background-color: var(--themeYellow);
    padding: 6px 22px;
    border-radius: 14px;
    text-transform: capitalize;
    background-color: linear-gradient(#FBC26D,#F09104);
    color: white;
    border: none;
    transition: all 0.3s;
}
.footballPage .poolCard .row1 .creatBtn:hover, .footballPage .poolCard .row2 .scoreM .creatBtn:hover {
    transition: all 0.3s;
    /* box-shadow: rgb(247, 178, 74) 0px 0px 8px; */
    box-shadow: rgb(247, 178, 74, 0.8) 0px 0px 8px 4px;
}
.footballPage .poolCard .row1 .title {
    font-size: 17px;
}
.footballPage .poolCard .row2 {
    padding: 20px 12px;
    display: flex;
    justify-content: space-around;
}
.footballPage .poolCard .row2 .scoreM{
    padding: 0 12px;
    font-size: 14px;
    text-align: center;
}

.footballPage .poolCard .row2 .scoreM .score {
    color: var(--themeYellow);
}
.footballPage .poolCard .row2 .scoreM .available {
    color: var(--themeYellow);
    border-radius: 12px;
    color: #000;
    background-color: var(--themeYellow);
    padding: 4px 20px;
}

.footballPage .gameMain {
    padding: 40px 0;
}
.footballPage .gameMain .gameCard {
    max-width: 250px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    cursor: pointer;
}
.footballPage .gameMain .gameCard .imgDiv {
    width: 80%;
    margin: auto;
}
.footballPage .gameMain .gameCard .imgDiv img {
    width: 100%;
    height: auto;
}
.footballPage .gameMain .gameCard .price {
    font-family: 'GothamMedium',serif;
    font-size: 18px;
    color: var(--themeYellow);
    text-transform: uppercase;
}

.betOptionsModal {
    margin: 20px;
    padding: 20px;
    border: 5px solid rgba(255, 255, 255, 0.25);
    border-radius: 30px;
    text-align: center;
}
.betOptionsModal h1 {
    width: max-content;
    margin: auto;
}
.betOptionsModal h1 {
    border-bottom: 3px solid rgba(255, 255, 255, 0.25);
}
.betOptionsModal .dropdown-item {
    color: #fff;
    font-size: 14px;
    text-align: center;
}
.betOptionsModal .dropD {
    border-bottom: 3px solid rgba(255, 255, 255, 0.25);
    border-radius: 12px;
    filter: drop-shadow(0 0 14.5px rgba(0, 0, 0, 0.1));
    background-image: linear-gradient(180deg, #8f8f8f 0%, #1e1d1d 100%);
    min-width: 200px;
    padding: 10px;
    color: #fff;
    margin: 20px 0;
    font-weight: bold;
    font-size: 19px;
    border: none;
}
.betOptionsModal .continue {
    color: #fff;
    font-weight: bold;
    border: none;
    position: relative;
    background-color: transparent;
    border-bottom: 2px solid #fff;
}
.betOptionsModal .continue::before {

    height: 2px;
    background-color: #fff;
    width: 100%;
    bottom: -3px;
}
.WalletModal.predModal .modal-content {
    box-shadow: inset 0 0 24px 1px #000;
}
.WalletModal.predModal .modal-dialog {
    max-width: 500px;
}

 @media screen and (min-width:1200px) {
    .footballPage .poolCard .row2 {
        padding-left: 130px;
        padding-right: 130px;
    }
 }
 @media screen and (min-width:576) {
    .footballPage .poolCard .row2 .scoreM {
        padding: 0 5px;
    }
 }
/* @media screen and (max-width:1400px) and (min-width:1200px) {
    .footballPage .footballSec .footballCardMain .fooCard .yellowBtn {
        font-size:13px;
    }
    .footballPage .footballSec .footballCardMain .fooCard .grayBtn {
        font-size:13px;
    }
    .footballPage .footballSec .footballCardMain .fooCard .title {
        font-size: 11px;
    }
} */
