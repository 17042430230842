footer{
  background-color: #181818;
  margin-left: -10px;
}


footer {
  background: #181818;
  border-top: 1px solid;
  border-image-source: 
  linear-gradient(111.12deg, #FBB040 -2.37%, rgba(206, 144, 52, 0) 30.92%, #FBB040 72.43%);
  border-image-slice: 1;
}
footer .footerInner {
  display: flex;
  margin-left: 40px;
  justify-content: space-between;
  /* max-width: 200px; */
  max-width: 330px;
  width: 100%;
}
footer .footerInner .col2 {
  padding-right: 10px;
}
footer .footerInner .col3 {
  padding: 0 10px;
}
footer .footerInner .col4 {
  padding-left: 10px;
}
footer ul li a:hover{
  color: #fff !important;
}
footer ul li:hover{
  color: #fff;
}
footer ul li {
  cursor: pointer;
  font-size: 15px;
  padding: 3px 0;
}

footer .kycImg {
  max-width: 130px;
}
footer .socialMain {
  display: flex;
  margin-top: 50px;
}
footer .socialMain .icon {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .socialMain .icon:hover {
  background: #fff;
}
footer .socialMain .icon:hover svg, footer .socialMain .icon:hover svg path {
  fill: #000;
}
footer .socialMain .icon svg, footer .socialMain .icon svg path {
  fill: #fff;
  opacity: 1;
}
footer .footerM {
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
}
footer .footerM .col1 p{
  max-width: 215px;
  margin-left: 65px;
}
footer .footerM .logo {
  width: 250px;
}
@media screen and (max-width:400px) {
  footer .footerM .logo {
    width: 200px !important;
  }
  footer .footerM .col1 p {
    margin-left: 52px;
  }
  footer .footerM .col1 h3 {
    margin-left: 50px !important;
  }
}
