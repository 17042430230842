.vipPageCenter {
    padding-right: 15px;
}
.vipPageCenter .bannerSec {
    padding: 80px 15px;
    text-align: center;
    background: url(../../imgs/vipBanner1.png);
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    border: 1px solid rgb(251, 176, 64, 0.3);
}
.vipPageCenter.raferalPage .bannerSec {
    background: url(../../imgs/referalBanner.jpg);
    background-position: center;
    background-size: cover;
}
.vipPageCenter .bannerSec h1 {
    font-size: 40px;
    color: #FBB040;
    line-height: 1;
}
.vipPageCenter .bannerSec .tagline {
    font-family: GothamRegular, serif;
    color: #fff;
    font-size: 16px;
    /* max-width: 260px; */
    margin: auto;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 0;
}
.vipPageCenter .bannerSec .joinBtn {
    text-align: center;
}
.vipPageCenter .bannerSec .joinBtn .themeBtn {
    padding: 10px 20px;
    font-size: 14px;
}
.vipPageCenter .sec2 {
    padding: 20px 0;
    text-align: center;
}
.vipPageCenter .sec2 h1 {
    font-size: 40px;
    color: #fff;
    max-width: 590px;
    margin: auto;
    margin-bottom: 10px;
}
.vipPageCenter .sec2 .tagline {
    font-family: GothamRegular, serif;
    color: #9E9E9E;
    font-size: 18px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.vipPageCenter .sec2 .tagline span {
    color: #FBB040;
}
.vipPageCenter table thead tr th {
    background-color: #0F0F0F;
    /* padding-top: 40px;
    padding-bottom: 40px; */
}
.vipPageCenter table tr td, .vipPageCenter table tr th {
    color: #fff;
    /* border-color: #353535; */
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
}
.vipPageCenter table tr td {
    padding-left: 0;
    padding-right: 0;
    font-family:  GothamRegular ,serif;
}
.vipPageCenter table tr td .inner {
    padding: 10px 20px;
    background-color: rgba(146, 146, 146, 0.13);
}
.vipPageCenter table tr td:first-child .inner {
    border-top-left-radius: 15px;   
    border-bottom-left-radius: 15px;   
}
.vipPageCenter table tr td:last-child .inner {}

.vipPageCenter table tr th {
    color: #9E9E9E;
    font-weight: 400;
    position: relative;
}
.vipPageCenter .table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: transparent;
}
.vipPageCenter .table-striped>tbody>tr:nth-of-type(even)>* {
    background-color: transparent;
}
.vipPageCenter .tableSec .card1 {
    position: absolute;
    z-index: 1;
    left: 110px;
    top: -50px;
    width: 150px;
}
.vipPageCenter .tableSec {
    position: relative;
    border: 1px solid rgb(251, 176, 64, 0.3);
    border-radius: 20px;
    overflow: hidden;
    padding: 15px;
}
.vipPageCenter .tableSec .table-responsive {
    /* padding-top: 50px; */
}
.vipPageCenter .tableSec .title {
    z-index: 2;
    position: relative;
}
.vipPageCenter .tableSec .card2 {
    position: absolute;
    z-index: 1;
    left: 125px;
    top: -50px;
    width: 150px;
}
.themeYellow {
    color: #FBB040 !important;
}
.vipPageCenter .sec3VipAccess {
    padding: 40px 0;
    /* text-align: center; */
}
.vipPageCenter .sec3VipAccess .aContainer {
    /* max-width: 500px; */
    margin: auto;
}
.vipPageCenter .sec3VipAccess .aContainer .heading {
    font-size: 28px;
    color: #fff;
}
.vipPageCenter .sec3VipAccess .aContainer .tagline {
    font-size: 16px;
    color: #9E9E9E;
}
.vipPageCenter .sec3VipAccess .aContainer  input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
.vipPageCenter .sec3VipAccess .aContainer  input {
    width: 100%;
    background: transparent;
    border: none;
    color: #fff;
    text-align: center;
}

.vipPageCenter .sec3VipAccess .aContainer .stakeBtn:hover:disabled {
    cursor: not-allowed;
    color: #141414;
    background: #F8AD3E;
}
.vipPageCenter .sec3VipAccess .aContainer .stakeBtn:disabled {
    opacity: 0.7;
}
.vipPageCenter .sec3VipAccess .aContainer .stakeBtn.silver {
    background: linear-gradient(90deg, #C7C7C7 0%, #878787 50%, #C7C7C7 100%);
}
.vipPageCenter .sec3VipAccess .aContainer .stakeBtn {
    padding: 12px 22px;
    color: #141414;
    font-size: 14px;
    border-radius: 14px;
    background: linear-gradient(90deg, rgba(255, 207, 135, 1) 0%, rgba(190, 136, 55, 1) 50%, rgba(255, 207, 135, 1) 100%);
    border: none;
    /* margin-top: 12px; */
    margin-bottom: 12px;
}
.vipPageCenter .sec3VipAccess .aContainer .stakeBtn:hover {
    background: transparent;
    color: #F8AD3E;
}
.vipPageCenter .vipRow1 {
    border: 1px solid rgb(251, 176, 64, 0.3);
    border-radius: 20px;
    overflow: hidden;
    padding: 15px;
    }
    .vipPageCenter .vipRow1 .col1 {
        height: 200px;
        border-radius: 18px;
        background: rgba(146, 146, 146, 0.13);
        padding: 12px 12px;
        display: flex;
        flex-direction: column;
        }
    .vipPageCenter .vipRow1 .col1 .goldHead {
        background: linear-gradient(90deg, rgba(255, 207, 135, 1) 0%, rgba(190, 136, 55, 1) 50%, rgba(255, 207, 135, 1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 24px;
        font-family: 'GothamBold';
        }
    .vipPageCenter .vipRow1 .col1 .silverHead {
        background: linear-gradient(90deg, #C7C7C7 0%, #878787 50%, #C7C7C7 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 24px;
        font-family: 'GothamBold';
        }
    .vipPageCenter .vipRow1 .col1 .tagline {
        font-size: 12px;
        color: #6C6C6C;
    }
    .vipPageCenter .vipRow1 .col1 .locking {
        font-size: 11px;
        background: linear-gradient(90deg, rgba(255, 207, 135, 1) 0%, rgba(190, 136, 55, 1) 50%, rgba(255, 207, 135, 1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        /* font-size: 24px; */
        margin-top: auto;
        padding-top: 20px;
        }
    .vipPageCenter .vipRow1.silver .col1 .locking {
        background: linear-gradient(90deg, #C7C7C7 0%, #878787 50%, #C7C7C7 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .vipPageCenter .vipRow1 .col2 .stakeAmount {
        display: flex;
        justify-content: space-between;
        background: rgba(146, 146, 146, 0.13);
        padding: 12px 20px;
        border-radius: 16px;
        margin-bottom: 12px;
        margin-top: 12px;
    }
 
    .vipPageCenter .vipRow1 .col2 .stakeAmount .val {
        font-size: 18px;
        background: linear-gradient(90deg, rgba(255, 207, 135, 1) 0%, rgba(190, 136, 55, 1) 50%, rgba(255, 207, 135, 1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        /* font-size: 24px; */
        font-family: 'GothamBold';
    }
    .vipPageCenter .vipRow1.silver .col2 .stakeAmount .val {
        background: linear-gradient(90deg, #C7C7C7 0%, #878787 50%, #C7C7C7 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        /* font-size: 24px; */
        font-family: 'GothamBold';
    }
    .vipPageCenter .vipRow1 .col3 {
        height: 200px;
        border-radius: 18px;
        background: rgba(146, 146, 146, 0.13);
        padding: 12px 12px;
        display: flex;
        flex-direction: column;
        }
    .vipPageCenter .vipRow1 .col3 .r1 {
        display: flex;
        justify-content: space-between;
    }
    .vipPageCenter .vipRow1 .col3 .goldHead {
        background: linear-gradient(90deg, rgba(255, 207, 135, 1) 0%, rgba(190, 136, 55, 1) 50%, rgba(255, 207, 135, 1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 28px;
        font-family: 'GothamBold';
        line-height: 1;
        }
        .vipPageCenter .vipRow1 .col3 .silverHead {
        background: linear-gradient(90deg, #C7C7C7 0%, #878787 50%, #C7C7C7 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 28px;
        font-family: 'GothamBold';
        line-height: 1;
    }
        .vipPageCenter .vipRow1 .col3 .r1 .tagline {
            color: #6C6C6C;
            line-height: 1.2;
            font-family: 'GothamLight';
            }
        .vipPageCenter .vipRow1 .col3 .freeBee {
            font-size: 11px;
            color: rgba(255, 255, 255, 0.3);
        }
        .ai-center {
            align-items: center;
        }
            


.jc-sb {
    justify-content: space-between;
}
.bb-gray {
    border-bottom: 1px solid #3F3F3F;
}
.icon-container svg {
    width: 20px;
}
.db-lg {
    display: block;
}

.db-sm {
    display: none;
}
.font-Gotham-medium {
    font-family: GothamMedium ,serif;
}
@media screen and (max-width:576px) {
    .db-lg {
        display: none;
    }
    .db-sm {
        display: block;
    }
}