/* :root{
  --bs-tertiary-bg-rgb: 24, 24, 24;
} */
#navbar-main{
  background-color: #181818 !important;
}
#nav-input{
  background-color: #000000;
  border: none;
}
.search-box{
  position: relative;
  max-width: 400px;
  width: 100%;
}
.search-box input{
  padding: 10px 0px 10px 30px;
  border-radius: 10px;
  color: white;
  width: 100%;
}
.search-right{
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.search-left{
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.theme-btn{
  color: black;
  border: none;
  border-radius: 15px;
  background-color: #FBB040;
  font-family: 'GothamLight';
  font-weight: 900;
  padding: 8px 25px;
  width: max-content;
  transition: all 0.2s;
}
.theme-btn:hover {
  box-shadow: rgb(247, 178, 74, 0.8) 0px 0px 8px 4px;
  transition: all 0.2s;
}
.theme-btn-green{
  color: black;
  border: none;
  border-radius: 20px;
  background-color: #8CC826;
  font-family: 'GothamLight';
  font-weight: 900;
  padding: 8px 25px;
  width: max-content;  
}

/* loader */
.beeLoader {
  position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  width: 100vw;
   left: 0;
  height: 100vh;
  background: rgba(0,0,0,.4);
  z-index: 111111;
}
.beeLoader .loader {
   width: 100%;
      max-width: 360px;
      padding: 40px 0;
      text-align: center;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      background: var(--themeYellow);
      border-radius: 20px;
      position: fixed;
      border: 8px solid #111;
  }

  .beeLoader .loader h3 {
      font-size: 22px;
      color: #fff;
  }

  .beeLoader img {
      width: 200px;
      filter: invert(1) brightness(0);
      /* animation: beat .4s infinite alternate; */
  }
      /* Heart beat animation */
      @keyframes beat{
        to { transform: scale(1.3);
        }
    }

    .loading__bar {
      position: relative;
      height: 3px;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      background-color: rgba(255, 255, 255,0.5);
      border-radius: 1em;
      overflow: hidden;
    }

    .loading__bar::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 50%;
      height: 100%;
      background: linear-gradient(90deg, #fff5, rgba(255, 255, 255, 0.891));
      animation: loading-animation 1.5s infinite;
      border-radius: 1em;
    }

    @keyframes loading-animation {
      0% {
        left: -50%;
      }

      100% {
        left: 150%;
      }
    }

    .spinLoading {
      width: 100px;
      height: 100px;
      margin: 30px auto;
      position: relative;
 }
  .spinBar >div {
      width: 10px;
      height: 30px;
      position: absolute;
      top: 35px;
      left: 45px;
      opacity: 1;
      -webkit-animation: fadeit 0.6s linear infinite;
      animation: fadeit 0.6s linear infinite;
 }
  .spinBar >div:nth-child(1) {
      background: #fff;
      -webkit-transform: rotate(0deg) translate(0,-30px);
      transform: rotate(0deg) translate(0,-30px);
      -webkit-animation-delay: 0.1s;
      animation-delay:  0.1s;
 }
  .spinBar >div:nth-child(2) {
      background: #fff;
      -webkit-transform: rotate(30deg) translate(0,-30px);
      transform: rotate(30deg) translate(0,-30px);
      -webkit-animation-delay: 0.15s;
      animation-delay:  0.15s;
 }
  .spinBar >div:nth-child(3) {
      background: #fff;
      -webkit-transform: rotate(60deg) translate(0,-30px);
      transform: rotate(60deg) translate(0,-30px);
      -webkit-animation-delay: 0.2s;
      animation-delay:  0.2s;
 }
  .spinBar >div:nth-child(4) {
      background: #fff;
      -webkit-transform: rotate(90deg) translate(0,-30px);
      transform: rotate(90deg) translate(0,-30px);
      -webkit-animation-delay: 0.25s;
      animation-delay:  0.25s;
 }
  .spinBar >div:nth-child(5) {
      background: #fff;
      -webkit-transform: rotate(120deg) translate(0,-30px);
      transform: rotate(120deg) translate(0,-30px);
      -webkit-animation-delay: 0.3s;
      animation-delay:  0.3s;
 }
  .spinBar >div:nth-child(6) {
      background: #fff;
      -webkit-transform: rotate(150deg) translate(0,-30px);
      transform: rotate(150deg) translate(0,-30px);
      -webkit-animation-delay: 0.35s;
      animation-delay:  0.35s;
 }
  .spinBar >div:nth-child(7) {
      background: #fff;
      -webkit-transform: rotate(180deg) translate(0,-30px);
      transform: rotate(180deg) translate(0,-30px);
      -webkit-animation-delay: 0.4s;
      animation-delay:  0.4s;
 }
  .spinBar >div:nth-child(8) {
      background: #fff;
      -webkit-transform: rotate(210deg) translate(0,-30px);
      transform: rotate(210deg) translate(0,-30px);
      -webkit-animation-delay: 0.45s;
      animation-delay:  0.45s;
 }
  .spinBar >div:nth-child(9) {
      background: #fff;
      -webkit-transform: rotate(240deg) translate(0,-30px);
      transform: rotate(240deg) translate(0,-30px);
      -webkit-animation-delay: 0.5s;
      animation-delay:  0.5s;
 }
  .spinBar >div:nth-child(10) {
      background: #fff;
      -webkit-transform: rotate(270deg) translate(0,-30px);
      transform: rotate(270deg) translate(0,-30px);
      -webkit-animation-delay: 0.55s;
      animation-delay:  0.55s;
 }
  .spinBar >div:nth-child(11) {
      background: #fff;
      -webkit-transform: rotate(300deg) translate(0,-30px);
      transform: rotate(300deg) translate(0,-30px);
      -webkit-animation-delay: 0.6s;
      animation-delay:  0.6s;
 }
  .spinBar >div:nth-child(12) {
      background: #fff;
      -webkit-transform: rotate(330deg) translate(0,-30px);
      transform: rotate(330deg) translate(0,-30px);
      -webkit-animation-delay: 0.65s;
      animation-delay:  0.65s;
 }
  @-webkit-keyframes fadeit {
      0% {
          opacity: 1;
     }
      100% {
          opacity: 0.2;
     }
 }
  @keyframes fadeit {
      0% {
          opacity: 1;
     }
      100% {
          opacity: 0.2;
     }
 }
/* loader */