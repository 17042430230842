.first-section{
  /* height: 380px; */
}
.play-now-first-section{
  background-image: url(../../imgs/dashboard-1.svg), url(../../imgs/dashboard-2.svg);
  background-blend-mode: color-burn;
  background-position: left top, center top;
  background-repeat: no-repeat;
  border-radius: 30px;
  background-size: cover;
  border: 1px solid rgb(251, 176, 64, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px;
}
.play-now-first-section > img {
  height: 45px;
}
.header-play-now-text2 {
  font-family: GothamMedium;
  color: white;
  font-size: 19px;
  font-weight: 500;
  max-width: 330px;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 30px;  
}
.header-play-now-text{
  font-family: GothamBold;
  color: white;
  font-size: 29px;
  font-weight: 900;
  text-transform: uppercase;
  max-width: 400px;
  line-height: 1.1;
  margin-bottom: 5px;
  margin-top: 20px;
}
.unleash-text{
  font-family: GothamLight;
  text-transform: uppercase;
  color: #FBB040;
  font-size: 12px;
  margin-bottom: 20px;
}
.tournaments-first-section{
  height: 100%;
  border: 1px solid black;
  border-radius: 30px;
  padding: 25px;
  border: 1px solid rgb(140, 200, 38, 0.5);
  background: linear-gradient(111.12deg, rgba(0, 0, 0, 0.6) 24.01%, rgba(27, 39, 5, 0.6) 140.92%);
}
.upcoming-tournaments{
  color: white;
  font-family: 'GothamLight';
  font-weight: 500;
  font-size: 22px;
}
.tournaments-first-section > .upcoming-tournaments-header > .upcoming-tournaments-tags > .theme-btn-green{
  font-size: 14px;
}
.upcoming-tournaments-header > .upcoming-tournaments-tags{
  margin: 15px 0px 35px 0px;
  display: flex;
}
.upcoming-tournament-card{
  display: flex;
  font-family: GothamLight;
  margin: 15px 0px;
}
.upcoming-tournament-card:last-child {
  margin-bottom: 0;
}
.upcoming-tournament-card > span {
  margin-right: 10px;
}
.upcoming-tournament-name{
  color: white;
}
.upcoming-tournament-date{
  color: rgb(140, 200, 38);
}
.game-section-header{
  font-size: 24px;
  color: white;
}
.game-section-header > span{
  color: #FBB040;
}
.game-section-view-all{
  font-family: GothamLight;
  font-size: 14px;
  /* color: #77797E; */
}
.game-name-div > .name{
  font-family: GothamBold;
  color: white;
  font-size: 20px;
}
.game-name-div > .players{
  font-family: GothamLight;
  margin-bottom: 0px;
  color: #77797E;
}
.game-name-div > .points-earned{
  background-color: #212121;
  color: white;
  font-family: GothamLight;
  border-radius: 15px;
  padding: 2px 12px;
  font-size: 12px;
}
.vip-banner{
  height: 350px;
  margin-top: 40px;
  background-image: url(../../imgs/vip-banner.png), url(../../imgs/vip-banner-2.png);
  background-blend-mode: color-burn;
  background-position: left top, center top;
  background-repeat: no-repeat;
  border-radius: 30px;
  background-size: cover;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.vip-banner > button {
  margin-bottom: 30px;
  margin-top: auto;
}
.vip-banner > img {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.service-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  border: 1px solid rgb(206, 144, 52, 0.5);
  box-shadow: 0px 10px 12px 0px #00000040;
  background: linear-gradient(0deg, #0E0E0E, #0E0E0E), linear-gradient(269.82deg, rgba(155, 98, 12, 0.1) 4.66%, rgba(14, 14, 14, 0.1) 98.13%);
  background-blend-mode: color-dodge;
}
.service-main > img {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 55px;
}
.service-main > .first-line{
  font-family: GothamLight;
  font-size: 22px;
  text-transform: uppercase;
  color: #C3C3C3;
  line-height: normal;
}
.service-main > .second-line, .service-main > .third-line{
  font-family: GothamBold;
  font-size: 34px;
  text-transform: uppercase;
  color: rgb(206, 144, 52);
  line-height: normal;
}
.service-main > .third-line{
  margin-bottom: 40px;
}
.service-main > .fourth-line{
  color: #D2D2D2;
  font-family: GothamLight;
  text-align: center;
  margin-bottom: 30px;
}
.service-main > .fifth-line{
  color: rgb(206, 144, 52);
  font-family: GothamBoldItalic;
  text-align: center;
  margin-bottom: 15px;
}
.service-header{
  font-size: 34px;
  color: white;
  line-height: normal;
}
.service-detail-point{
  color: #DCDCDC;
  font-size: 15px;
}
.tournament-header{
  color: white;
  font-weight: 600;
  font-size: 18px;
}
.tournament-dd > div{
  background-color: #1F1F1F !important;
  border: 1px solid #1F1F1F;
  border-radius: 12px;
}
.tournament-dd > div > a{
  color: #D2D2D2;
}
.tournament-dd > button{
  background-color: #1F1F1F !important;
  border: 1px solid #1F1F1F;
  border-radius: 12px;
  width: 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tournament-prev, .tournament-next{
  background-color: #1F1F1F !important;
  border-radius: 12px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
}
.tournament-card{
  padding: 20px;
  border: 1px solid rgb(206, 144, 52, 0.4);
  margin: auto;
  margin-bottom: 10px;
  border-radius: 16px;
  background-color: #181818;
  max-width: 400px;

}

.classicGameSec {
  padding: 30px 0;
}
.classicGameSec .headMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.classicGameSec .headMain .view {
  cursor: pointer;
}
.classicGameSec .gameCard {
  margin: auto;
  margin-bottom: 20px;
  max-width: 230px;
  cursor: pointer;
  position: relative;
  top: 0;
  transition: all 0.3s;
  }
  .classicGameSec .gameCard .imgDiv {
    position: relative;
    transition: all 0.3s;
  }
  .classicGameSec .gameCard .imgDiv .icon {
    position: absolute;
    max-width: 80%;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 0.3s;
  }
  .classicGameSec .gameCard .imgDiv .cardImg {
    transition: all 0.3s;
  }
  .classicGameSec .gameCard .imgDiv:hover .cardImg {
    filter: brightness(0.5);
    transition: all 0.3s;
}
.classicGameSec .gameCard .imgDiv:hover .icon{
  bottom: 40%;
  transition: all 0.3s;
}
.classicGameSec .gameCard .name {
  font-size: 17px;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
  }
  .classicGameSec .gameCard .imgDiv .themeBtn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%,0);
    width: max-content;
    /* display: none; */
    text-decoration: none;
    opacity: 0;
    transition: all 0.3s;
  }
.classicGameSec .gameCard .imgDiv:hover .themeBtn {
  opacity: 1;
  transition: all 0.3s;
  color: #000;
}
.classicGameSec .gameCard .players {
  color: #77797E;
  
}
.classicGameSec .gameCard .badge {
  padding: 4px 12px;
  border-radius: 16px ;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.1);
}
 .homeHead {
  font-size: 24px;
  font-weight: 400;
  /* margin-bottom: 20px; */
}
 .homeHead span {
  font-weight: bold;
  color: #FBB040;
}

.dashboardPage {
  padding-left: 3rem;
  padding-right: 3rem;
}
.dashboardPage .vipSec {
  padding: 30px;
  padding-top: 150px;
  padding-bottom: 70px;
  margin: 40px 0;
  /* min-height: 400px; */
  background: url(../../imgs/vipBanner.png);
  background-size: cover;
  color: #fff;
  border: 0.5px solid #fbb0408a;
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dashboardPage .vipSec .vipRow {
  display: flex;
  align-items: center;
}
.dashboardPage .vipSec .vipRow .col1 {
  display: flex;
  align-items: center;
}
.dashboardPage .vipSec .vipRow .col1 .icon {
  width: 60px;
  margin-right: 10px;
}
.dashboardPage .vipSec .vipRow .col1 .vip {
  background: linear-gradient(141.52deg, rgba(255, 201, 120, 0.91) 4.6%, #956826 78.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42px;
  font-weight: 800;
  line-height: 1;
}
.dashboardPage .vipSec .vipRow .col1 .text {
  font-size: 27px;
  max-width: 100px;
  line-height: 1;
  font-weight: bold;
}
.dashboardPage .vipSec .vipRow .bar {
  margin: 0 30px;
  width: 1px;
  height: 82px;
  background: #FBB040;
}
.dashboardPage .vipSec .vipRow .col2 .unlimited {
  color: #C8C8C8;
  font-size: 35px;
  text-transform: uppercase;
}
.dashboardPage .vipSec .vipRow .col2 .vip {
  font-size: 58px;
  font-weight: 800;
  line-height: 1;
}
.dashboardPage .vipSec .vipRow .col2 .vip span {
  background: linear-gradient(141.52deg, rgba(255, 201, 120, 0.91) 4.6%, #956826 78.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.dashboardPage .vipSec .themeBtn {
 margin-top: 50px;
 font-weight: 500;
 padding-left: 40px;
 padding-right: 40px;
}
.jc-center {
  justify-content: center;
}
.dashboardPage .partnersSec {
  padding: 50px 0;
}
.dashboardPage .partnersSec h2 {
  font-family: GothamBold;
  font-size: 34px;
  text-transform: uppercase;
  color: rgb(206, 144, 52);
  line-height: normal;
  text-align: center;
  margin-bottom: 30px;
}
.dashboardPage .partnersSec .imgDiv {
  max-width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

@media screen and (min-width: 1200px) and (max-width:1400px) {
  .classicGameSec .gameCard .name {
    font-size: 13px;
  }
  .classicGameSec .gameCard .badge {
    padding: 4px 8px;
    font-size: 10px;
  }
  .classicGameSec .gameCard .players {
    font-size: 13px;
  }
  
}
@media screen and (min-width: 1200px) {
  .classicGameSec .col-xl-3 {
      width: 20%;
  }
}
@media screen and (max-width:1200px) {
  .play-now-first-section {
    margin-bottom: 20px;
    height: auto;
  }
}
@media screen and (max-width:992px) {
  .dashboardPage .vipSec .vipRow {
    flex-direction: column;
    text-align: center;
  }
  .dashboardPage .vipSec .vipRow .bar {
    height: 1px;
    width: 82px;
    margin: 30px;
  }
  .dashboardPage .vipSec .vipRow .col2 .unlimited {
    font-size: 25px;
  }
  .dashboardPage .vipSec .vipRow .col2 .vip {
    font-size: 40px;
  }
}
@media screen and (max-width:768px) {
  .dashboardPage {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (max-width:576px) {
  .header-play-now-text {
    font-size: 25px;
  }
  .service-header {
    margin-top: 20px;
  }
  .service-detail-point {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
.dashboardPage .partnersSec .imgDiv {
  margin-bottom: 40px;
  max-width: 70%;
}
}
@media screen and (max-width:400px) {
  .classicGameSec .gameCard {
    max-width: 100%;
  }
  .homeHead {
    font-size: 18px;
  }
  .play-now-first-section {
    padding: 16px;
  }
  .tournaments-first-section {
    padding: 16px;
  }
  .tournaments-first-section > .upcoming-tournaments-header > .upcoming-tournaments-tags > .theme-btn-green {
    font-size: 12px;
  }
  .upcoming-tournaments {
    font-size: 20px;
  }
  .upcoming-tournament-name {
    font-size: 14px;
  }
  .upcoming-tournament-date{
    font-size: 14px;
  }
  .dashboardPage .vipSec .vipRow .col2 .vip {
    font-size: 34px;
  }
  .dashboardPage .vipSec .vipRow .col2 .unlimited {
    font-size: 21px;
  }
  .dashboardPage .vipSec {
    padding: 50px 16px;
  }
  .dashboardPage .vipSec .vipRow .col1 .icon {
    width: 40px;
  }
  .dashboardPage .vipSec .vipRow .col1 .vip {
    font-size: 32px;
  }
  .dashboardPage .vipSec .vipRow .col1 .text {
    font-size: 23px;
  }
  .tournament-card {
    padding: 16px;
  }
  .service-main > img {
    width: 45px;
  }
  .service-main > .first-line {
    font-size: 20px;
  }
  .service-main > .second-line, .service-main > .third-line {
    font-size: 28px;
  }
  .service-main > .third-line {
    margin-bottom: 20px;
  }
  .service-main > .fourth-line {
    font-size: 14px;
    text-align: left;
  }
  .service-main > .fifth-line {
    font-size: 14px;
  }
  .service-header {
    font-size: 28px;
  }
 

}