.cover-and-pfp-container{
  position: relative;
}
.profile-img-container{
  position: absolute;
  bottom: -125px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;  
}
.profile-username {
  font-size: 32px;
}
.profile-username > img{
  margin-left: 6px;
}
.profile-username > span{
  border: 1px solid #FBB040;
  font-size: 11px;
  border-radius: 11px;
  padding: 4px 6px;
}
.profile-address{
  font-family: GothamLight;
  color: #C9C9C9;
  font-size: 12px;
}
.profile-profile-img{
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.profile-cover-img{
  width: 100%;
  height: 295px;
}
.cover-edit-container-main{
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.cover-edit-container{
  width: fit-content;
  cursor: pointer;
  position: relative;
}
.cover-edit-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.profile-tab-btns{
  background-color: #121212;
  color: #656565;
  font-size: 24px;
  font-family: GothamLight;
  text-align: center;
  padding: 0px;
}
.profile-tab-btn.active{
  background-color: rgb(251, 176, 64);
  color: black;
  font-family: GothamBold;
}
.profile-tab-btn{
  padding: 10px 0px;
  cursor: pointer;
  border-top: 1px solid rgb(63, 63, 63, 0.5);
  border-bottom: 1px solid rgb(63, 63, 63, 0.5);
}
.stats-detail-btn{
  display: flex;
  color: white;
  align-items: center;
  position: relative;
  justify-content: center;
}
.stats-detail-btn > .stats-line{
  left: 0;
  position: absolute;
}
.stats-text-container{
  display: flex;
  padding-top: 65px;
  padding-bottom: 65px;
  margin-left: 10px;
  flex-direction: column;
}
.stats-num{
  font-family: GothamBoldItalic;
  font-size: 32px;
  line-height: 40px;
}
.stats-text{
  font-family: GothamBoldItalic;
  color: #C9C9C9;
  font-size: 12px;
}
.leaderboard-header{
  justify-content: space-between;
  display: flex;
  padding: 25px 35px;
  align-items: center;
}
.leaderboard-header-left{
  display: flex;
}
.leaderboard-header-text{
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  color: white;
  font-size: 24px;
}
.leaderboard-text{
  font-weight: 900;
}
.leaderboard-rank-text{
  font-weight: 900;
  font-family: GothamLight;
}
#leaderbaord-dropdown-basic{
  background-color: #0F0F0F;
  border: 0.5px solid #3F3F3F;
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  border-radius: 8px;
}
#leaderbaord-dropdown-basic::after{
  display: none;
}
.leaderboard-dd > div{
  width: 100%;
  background-color: #0F0F0F;
}
.leaderboard-pre-table{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.leaderboard-pre-table > div > span.active{
  color: #FBB040;
  font-family: GothamMedium;
}
.refer-header{
  /* box-shadow: 0px 10px 12px 0px #00000040; */
  background: url(../../imgs/refer-bg.png);
  color: white;
}
.claim-div{
  background: url(../../imgs/claim-bg.png);
  border-radius: 0px 30px 30px 0px;
}
.referalStatsRow {
  padding: 20px;
}
.referalStatsRow .referalStatsCard {
  justify-content: center;
  padding: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  border-right: 1px solid #646464;
} 

.referalStatsRow .referalStatsCard img {
  width: 62px;
  margin-right: 15px;
}
.referalStatsRow .referalStatsCard .number {
  font-size: 30px;
  font-style: italic;
  line-height: 1;
}
.referalStatsRow .referalStatsCard .title {
  color: #C9C9C9;
  max-width: 100px;
}
.refTable tr td {
  color: #fff;
}
.friendsSecM .reqCard {
  border: 1px solid rgb(251, 176, 64, 0.3);
  border-radius: 25px;
  padding: 15px;
  display: flex;
  color: #fff;
  align-items: center;
  margin-bottom: 20px;
}
.friendsSecM .reqCard .imgDiv {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 12px;
  color: #fff;
  flex-shrink: 0;
}
.friendsSecM .reqCard .imgDiv img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.friendsSecM .seeMore {
  text-align: center;
  color: #FBB040;
  margin: 20px 0;
}

.friendsSecM .reqCard .content .name {
  font-size: 24px;
  margin-bottom: 8px;
}
.friendsSecM .reqCard .content .name span {
  border: 1px solid #FBB040;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 11px;
}
.friendsSecM .reqCard .content .btnM .confirm {
  background: #FBB040;
    padding: 4px 20px;
    border: none;
    border-radius: 20px;
    color: #000;
    font-weight: 700;
    margin-right: 10px;
    font-size: 13px;
  }
  .friendsSecM .reqCard .content .btnM .confirm:hover {
    opacity: 0.8;
  }
  .friendsSecM .reqCard .content .btnM .delete {
    background: #FBB040;
      padding: 4px 20px;
      border: none;
      border-radius: 20px;
      color: #fff;
      font-weight: 700;
      margin-right: 10px;  
      background: rgb(255, 255, 255, 0.3);
      font-size: 13px;
    }
    .friendsSecM .reqCard .content .btnM .delete:hover {
      opacity: 0.8;
    }
  .friendsSecM .reqCard .content .btnM {
      display: flex;
      flex-wrap: wrap;
  }
  .friendsSecM .friendSec {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid rgb(251, 176, 64, 0.3);
      border-radius: 30px;
      padding: 20px;
  }
  .friendsSecM .friendSec .fCard {
      max-width: 140px;
      padding: 10px;
      color: #fff;
  }
  .friendsSecM .friendSec .fCard .imgDiv {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      overflow: hidden;
      margin: auto;
  }
  .friendsSecM .friendSec .fCard .imgDiv img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
  }
  .friendsSecM .friendSec .fCard .name {
      font-size: 22px;
      margin-bottom: 8px;
      word-break: break-all;
      text-align: center;
  }
  .friendsSecM .friendSec .fCard .cBtn {
      background: #FBB040;
      padding: 4px 10px;
      border: none;
      border-radius: 20px;
      color: #000;
      font-weight: 700;
      width: 100%;
      /* margin-right: 10px; */
      font-size: 13px;
      margin-bottom: 7px;
  }
  .friendsSecM .friendSec .fCard .iconM {
      display: flex;
  }
  .friendsSecM .friendSec .fCard .iconM .icon {
      border: 0.5px solid #FFFFFF80;
      border-radius: 10px;
      width: 100%;
      text-align: center;
      margin-right: 5px;
      cursor: pointer;
  }
  .friendsSecM .friendSec .fCard .iconM .icon:last-child {
      margin: 0;
  }
  .friendsSecM .heading {
      color: #fff;
      font-weight: bold;
      font-size: 40px;
  }
  .friendsSecM .addFriendCard {
      border-radius: 15px;
      overflow: hidden;
      margin-bottom: 20px;
  }
  .friendsSecM .addFriendCard .imgDiv {
    height: 250px;
    width: 100%;
  }
  .friendsSecM .addFriendCard .imgDiv img {
    height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
  .friendsSecM .addFriendCard .content {
    padding: 10px;
    border: 1px solid rgb(251, 176, 64, 0.3);
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /* margin-bottom: 16px; */
    color: #fff;
  }
  .friendsSecM .addFriendCard .content {
    font-size: 22px;
    margin-bottom: 8px;
    word-break: break-all;
    margin: 0;
  }
  .friendsSecM .addFriendCard .content .mutual {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .friendsSecM .addFriendCard .content .mutual span {
    color: #FBB040;
  }
  .friendsSecM .addFriendCard .content .btnM {
    display: flex;
  }
  .friendsSecM .addFriendCard .content .btnM .confirm {
    background: #FBB040;
      padding: 4px 20px;
      border: none;
      border-radius: 20px;
      color: #000;
      font-weight: 700;
      margin-right: 10px;
      font-size: 13px;
      width: 100%;
    } 
  .friendsSecM .addFriendCard .content .btnM .confirm:hover {
    opacity: 0.8;
  }
  .friendsSecM .addFriendCard .content .btnM .delete {
    padding: 4px 10px;
    border: none;
    border-radius: 20px;
    color: #fff;
    font-weight: 700;
    background: rgb(255, 255, 255, 0.3);
    font-size: 13px;
    width: 100%;
  }
  .friendsSecM .addFriendCard .content .btnM .delete:hover {
    opacity: 0.8;
  }
  .friendsSecM .mayLike {
    color: #FBB040;
    font-size: 22px;
  }

  /* settingPage */
  .settingPage {
    padding: 20px 38px;
  }
  .settingPage .idRow {
    border: 1px solid rgb(251, 176, 64, 0.3);
    border-radius: 25px;
    padding: 15px;
    color: #fff;
    display: flex;
  }
  .settingPage .idRow .col1 {
    max-width: 270px;
    padding: 30px 20px;
    border-radius: 15px;
    background: #191919;
    margin-right: 20px;
  }
  .settingPage .idRow .col1 .title {
    font-size: 17px;
    margin-bottom: 7px;
  }
  .settingPage .idRow .col1 .desc {
    color: #6C6C6C;
    font-size: 12px;
  }
  .settingPage .idRow .col1 .desc span {
    color: #FBB040;
  }
  .settingPage .idRow .col2 .r1 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  .settingPage .idRow .col2 .r1 .beeIdM {
    background: #191919;
    /* padding: 10px; */
    border-radius: 15px;
    display: flex;
    margin-right: 20px;
    font-size: 20px;
  }
  .settingPage .idRow .col2 .r1 .beeIdM .title {
    padding: 14px 25px;
    font-size: 22px;
  }
  .settingPage .idRow .col2 .r1 .beeIdM .id {
    padding: 14px 25px;
    color: #858585;
  }
  .settingPage .idRow .col2 .r1 .profileCol {
    display: flex;
    align-items: center;
  }
  .settingPage .idRow .col2 .r1 .profileCol .imgDiv {
    width: 65px;
    height: 65px;
    border: 1px solid rgb(251, 176, 64, 0.3);
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .settingPage .idRow .col2 .r1 .profileCol .imgDiv img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  .settingPage .idRow .col2 .r1 .profileCol .upload {
    border: 1px solid rgb(251, 176, 64, 0.3);
    padding: 7px 25px;
    background: no-repeat;
    border-radius: 25px;
    color: #fff;
  }
  .settingPage .idRow .saveBtn {
    background-color: rgb(251, 176, 64);
    color: black;
    font-family: GothamBold;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 16px;
    border: none;
    margin-right: 25px;
  }
  .settingPage .idRow .saveBtn:hover {
    opacity: 0.8;
  }
  .settingPage .idRow .col2 .r2 .walletBtn {
    border: 1px solid rgb(251, 176, 64, 0.3);
    padding: 7px 25px;
    background: no-repeat;
    border-radius: 25px;
    color: #fff;
  }
  .settingPage .profileMain {
    width: 100%;
  }
  .settingPage .profileMain .mailRow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .settingPage .profileMain .mailRow .icon {
    width: 40px;
    margin-right: 10px;
  }
  .settingPage .profileMain .mailRow .inputM {
    background: #191919;
    padding: 10px 30px;
    border-radius: 15px;
    width: fit-content;
  }
  .settingPage .profileMain .mailRow .inputM label {
    color: #6C6C6C;
    font-size: 12px;
  }

  .settingPage .profileMain .mailRow .inputM input {
    background: no-repeat;
    border: none;
    width: 100%;
    font-size: 20px;
    color: #fff;
  }
  .settingPage .profileMain .basicM {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .settingPage .profileMain .basicM .basic {
    background: #191919;
    border-radius: 15px;
    padding: 10px 20px;
    margin-right: 10px;
  }
  

  .leaderboard-dd > .dropdown-menu > .dropdown-item{
    color: white;
  }
  .leaderboard-dd > .dropdown-menu > .dropdown-item:hover{
    color: black;
  }