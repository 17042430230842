#sidebar.navcollapsed{
  top: 170px;
}
#sidebar {
  top: 81px;
  position: fixed;
  border-color:rgb(247, 178, 74);
  z-index: 1;
}

#sidebar > div{
  height: calc(100vh - 81px);
  position: relative;
}
#sidebar > div::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #000;
}

#sidebar > div::-webkit-scrollbar
{
	width: 6px;
	background-color: #000;
}

#sidebar > div::-webkit-scrollbar-thumb
{
	background-color: rgb(56, 56, 56);
    border: 1px solid rgb(56, 56, 56);
}

.ps-menu-root, .ps-menu-root > ul{
  height: 100%;
}

.sidebar-collapse-item > a {
  flex-direction: row-reverse;
  height: auto !important;
}

.sidebar-collapse-item > a > span{
  margin-right: 0px;
}
.sidebar-collapse-item > a > span > img {
  transform: rotate(0deg);
  transition: transform .3s ease-in-out;
}
.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > .sidebar-collapse-item > a > span > img{
  transform: rotate(180deg);
  transition: transform .3s ease-in-out;
}
.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > .ps-submenu-root.sidebar-item > .ps-submenu-content {
  position: absolute !important;
  transform: translate3d(74.4px, 0px, 0px) !important;
  width: 300px;
}

/* .ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > .sidebar-item > a{
  background: transparent !important;
  border: none;
}
.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > .sidebar-item > a > .ps-menu-icon {
  margin-right: 0px;
  background: #090602 !important;
  border: 1px solid #FBB040;
  border-radius: 10px;
  padding: 19px;
}  */

.sidebar-collapse-item > .ps-menu-button:hover{
  color: white;
  background-color: #181818 !important;
}

.ps-menu-button {
  align-items: center;
}
.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > .sidebar-item > .ps-menu-button {
  padding: 0;
  margin: 10px;
}
.sidebar-item > .ps-menu-button:hover{
  color: white;
  background-color: black !important;
}
.sidebar-item.dashboard-item > .ps-menu-button{
  margin-top: 10px;
}
.sidebar-item > .ps-menu-button {
  margin: 20px 20px 0px;
  background: black;
  border-radius: 16px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.sidebar-item > .ps-active{
  background: #090602 !important;
  border: 1px solid #FBB040;
}

.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > .sidebar-item.text,
.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > .sidebar-item.faq-and-report,
.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > div > .sidebar-item.signup-and-login,
.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > div > .sidebar-item.logged-in-items,
.ps-collapsed > .ps-sidebar-container > .ps-menu-root > ul > .sidebar-collapse-item > a > .ps-menu-label {
  display: none;
}

.sidebar-item.text{
  height: 35px;
}
.sidebar-item.text > a{
  cursor: auto;
}
.sidebar-item.profile-pic > a.ps-menu-button, .sidebar-item.profile-pic > a.ps-menu-button:hover{
  background-color: transparent !important;
  color: #77797E;
  cursor: default;
  height: auto;
}
.sidebar-item.logged-in-items > a.ps-menu-button, .sidebar-item.logged-in-items > a.ps-menu-button:hover{
  background-color: transparent !important;
  color: #77797E;
  cursor: default;
  height: auto;
}
.logged-in-items-div{
  background-color: #111111;
  border-radius: 12px;
  padding: 12px;
  border: .5px solid rgb(251 176 64 / 30%);
}
.logged-in-items-div > div:last-child{
  margin-bottom: 0px;
  background: black;
}
.logged-in-items-div > div > img{
  margin-right: 15px;
}
.logged-in-items-div > div{
  border-radius: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 6px 25px;
  background: linear-gradient(90deg, #181818 0%, #111010 100%);
}
.sidebar-item.text > .ps-menu-button:hover, .sidebar-item.faq-and-report > .ps-menu-button:hover,
.sidebar-item.signup-and-login > .ps-menu-button:hover {
  color: #77797E;
  background-color: #181818 !important;
}
.sidebar-item.text > .ps-menu-button, .sidebar-item.faq-and-report > .ps-menu-button,
.sidebar-item.signup-and-login > .ps-menu-button {
  margin: 0px 0px 0px 20px;
  background: none;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.sidebar-item.faq-and-report > .ps-menu-button > .ps-menu-label{
  display: flex;
}
.sidebar-item.faq-and-report > .ps-menu-button > .ps-menu-label > div{
  background-color: #212121;
  background-color: #212121;
  padding: 5px 30px;
  border-radius: 10px;
  margin-right: 10px;
}
.sidebar-item.signup-and-login > .ps-menu-button > .ps-menu-label > div{
  border: 1px solid #FBB040;
  display: flex;
  background-color: #212121;
  border-radius: 16px;
  margin-right: 10px;
}
.sidebar-item.signup-and-login > .ps-menu-button > .ps-menu-label > div > div{
  flex: 1;
  text-align: center;
  padding: 10px;
  font-weight: 600;
}
.WalletModal h1{
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  color: #fff;
}
.WalletModal .walletRow{
  display: flex;
  justify-content: center;
}
.WalletModal .walletImg .img-fluid{
  max-width: 60%;
}
.WalletModal .walletImg{
  width: 110px;
  height: 110px;
  margin: auto;
  margin-bottom: 30px;
  text-align: center;
  background-image: linear-gradient(255deg, #111 0%, #454545 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.WalletModal .walletConnect .walletImg img {
  filter: brightness(0) invert(1) ;
}
.WalletModal .walletConnect .walletImg{
  background-image: linear-gradient(255deg, #1379fd 0%, #4f95f7 100%);
  /* width: 226px;
  margin: auto;
  margin-bottom: 30px; */
}
.WalletModal .btnDiv {
  text-align: center;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.WalletModal .btnDiv button span {
  color: #fff;
}
.WalletModal .btnDiv button{
  width: 200px;
  height: 55px;
  border-radius: 12px;
  border: none;
  font-weight: bold;
  color: #fff;
  margin: auto;
  border-radius: 12px;
  filter: drop-shadow(0 0 14.5px rgba(0,0,0,0.1));
  background-image: linear-gradient(180deg, #8f8f8f 0%, #1e1d1d 100%);
}
.WalletModal .btnDiv button:hover{
  box-shadow: rgb(247, 178, 74) 0px 0px 8px;
}
.WalletModal label {
  color: #000;
}
.WalletModal input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 12px;
  background: rgb(36, 36, 36);
  color: #9e9e9e;
}
.WalletModal .helpDv{
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;
}
.WalletModal .helpDv img{
  width: 20px;
  margin-right: 5px;
}
.WalletModal .closeIcon {
  position: absolute;
  top: 30px;
  right: 20px;
}
.WalletModal .closeIcon i{
  font-size: 25px;
  color: #707070;
  cursor: pointer;
}
.modal {
  backdrop-filter: blur(5px);
}
.modal-dialog {
  max-width: 500px !important;
}
.modal-content {
  border-radius: 20px !important;
  background-color: rgb(36, 36, 36) !important;
}
.WalletModal .modal-content {
  border-radius: 50px !important;
  background-color: rgb(247, 178, 74) !important;
  border: 8px solid #111;
}
.WalletModal .modal-content .claimInputMain {
  display: flex;
  justify-content: space-between;
  text-align: left;
  color: #fff;
  margin-top: 40px;
}
.WalletModal .modal-content .claimInputMain input {
  width: 100%;
}
.WalletModal .modal-content .claimInputMain label {
  color: #fff;
}

.passInput {
  position: relative;
}
.passInput img{ 
  width: 20px;
  position: absolute;
  right: 15px;
  top: 13px;
  cursor: pointer;
}
.signup-and-login.collapsed .ps-menu-button{
  margin: 0;
} 
.sidebar-item.signup-and-login.collapsed > .ps-menu-button > .ps-menu-label > div {
  flex-direction: column;
  margin: 0;
}
.sidebar-item.signup-and-login.collapsed  > .ps-menu-button > .ps-menu-label > div > div {
  font-size: 10px;
  padding: 8px 2px;
  border-radius:16px !important;
}
.logged-in-items.collapsed .ps-menu-button {
  margin-left: 7px;
  margin-right: 7px;
}
.logged-in-items.collapsed .logged-in-items-div > div {
  padding-left: 0;
  padding-right: 0;
}
.logged-in-items.collapsed .ps-menu-button {
  margin-left: 10px;
}
.profile-pic.collapsed .ps-menu-button {
  margin-left: 11px;
}
.ps-submenu-content{
  background-color: transparent !important;
}
.games-names{
  margin-bottom: 5px;
  }
  .games-names .icon {
    margin-right: 7px;
    display: inline-block;
    }
  .games-names .icon svg {
    width: 20px;
    height: 17px;

  }
  
  .games-names > .games-types{
  font-family: GothamBold;
  color: white;
  font-size: 18px;
}
.games-names > .games-name{
  font-family: GothamLight;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  word-break: break-all;
}
.logged-in-items-div > div:hover {
  color: white;
}
.signupBtn {
  background-color: #FBB040;
  color: black; 
  border-radius: 16px 0px 0px 16px;
}
.signupBtn:hover {
  color: #FBB040;
  background-color: transparent;
}
.signBtn {
  border-radius: 0px 16px 16px 0px; 
  color: #FBB040;
}
.signBtn:hover {
  color: black; 
  background-color: #FBB040;
}

.signModal input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 12px;
  background: transparent;
  color: #9e9e9e;
}
.signModal .logo {
  max-width: 150px;
  margin: auto;
  }
  .signModal .navigate {
    display: flex;
    border-radius: 14px;
    overflow: hidden;
    background-color: #000;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .signModal .navigate button {
    flex: 1;
    background: transparent;
    color: #a5a5a5;
    font-size: 18px;
    padding: 10px;
    border: none;
  }
  .signModal .navigate button.active {
    background: #FBB040;
    color: #000;
  }
  .signModal .inputMain {
    display: flex;
    align-items: center;
    background: #000;
    padding: 0px 16px;
    border-radius: 12px;
    margin-bottom: 10px;
    height: 44px;
    }
    .signModal .inputMain input {
      border: none;
      outline: none;
    }
    .signModal .inputMain .ico {
      width: 20px;
      font-size: 18px;
      }
      .signModal .already {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 10px;
      }
      .signModal .already span {
        color: #FBB040;
        cursor: pointer;
      }
    
    .signModal .or {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin: 20px 0;
      }
    .signModal .or span {
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      width: 100%;
    }
    .signModal .closeIcon {
      position: absolute;
      top: 15px;
      right: 20px;
    }
    .signModal .closeIcon i{
      font-size: 25px;
      color: #707070;
      cursor: pointer;
    }
  .signModal .modal-body {
    padding: 20px 30px;
  }
  .signModal .forget-pass {
    color: #FBB040;
  }
  #sidebar.css-1gnzbyf.ps-collapsed > div {
    overflow: unset;
  }

  @media screen and (min-width:992px) {
    #sidebar > div {
      overflow: auto;
    }
    .css-18unl23 {
      position: absolute !important;
    }
    .ps-submenu-content.css-18unl23 {
      overflow: auto;
      height: auto;
      /* position: absolute !important; */
      /* left: 100%; */
      /* top: 0; */
      /* width: 100%; */
      height: 0;

      position: fixed !important;
      left: 320px;
      top: 90px;
      max-width: 320px;
    }
  }